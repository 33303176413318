enum DataType {
  Date,
  DateTime,
  TimeWithSecond,
  TimeWithOutSecond,
  String,
  Number,
  Boolean,
  NullAbleBoolean,
  Action,
  NumberWithMantissa2,
  NumberWithMantissa4,
  NumberWithMantissa2AndPercentageSign,
  NumberWithMantissa2AndPreserveZero,
  NumberWithMantissa2AndPreserveZeroAndPercentageSign,
  NumberWithMantissa4AndPreserveZero,
}

export default DataType;
