import React, { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import EmbeddedContainer from 'site/bond/component/page/EmbeddedContainer';
import AdvanceMenuContainer from './AdvanceMenuContainer';
import { Box } from '@material-ui/core';
import WatchListPage from 'site/bond/component/page/WatchList/WatchListPage';

const AdvancePage: FC = () => {
  return (
    <Box textAlign="center">
      <AdvanceMenuContainer />
      <Switch>
        <Route path={'/advance/watchList'} component={WatchListPage} />
        <Route
          path="/advance/combination"
          render={() => (
            <Box mt={1}>
              <EmbeddedContainer ChaoLianJie="/bond/BondCollections_Iframe.aspx" />
            </Box>
          )}
        />
        <Redirect from="/advance" to="/advance/watchList" />
      </Switch>
    </Box>
  );
};

export default AdvancePage;
