// 判斷字元是否為中文
import DataType from 'constant/datatype';
import moment from 'moment';
import DATETIME from 'constant/datetime';
import { numberFormatter } from 'lib/number';
import DATA from 'constant/data';

export const charactorIsChinese = (c: number) => c >= 0x4e00 && c <= 0x9fa5;

// 回傳有多少中文字元在字串
export const chineseCharInString = (str: string) =>
  str.split('').reduce((prev, curr) => prev + (charactorIsChinese(curr.charCodeAt(0)) ? 1 : 0), 0);

export const calcStringWidth = (() => {
  const canvasElement = document.createElement('canvas');
  const ctx = canvasElement.getContext('2d');

  if (ctx === null) {
    throw Error('canvas get context as null');
  }

  return (txt, fontsize, fontname = '微軟正黑體') => {
    ctx.font = fontsize + ' ' + fontname;

    return ctx.measureText(txt).width;
  };
})();

export function dataFormatter(text, dataType) {

  let result: string = text;
  if (dataType === DataType.Boolean) {
    result = text ? '是' : '否';
    return result;
  }

  if (text === '' || text === null || text === undefined || text === DATA.NULL) return DATA.NULL;
  switch (dataType) {
    case DataType.NullAbleBoolean:
      result = text ? '是' : '否';
      break;
    case DataType.Date:
      result = moment(text).format(DATETIME.UNIVERSAL_DATE_FORMAT);
      break;
    case DataType.DateTime:
      result = moment(text).format(DATETIME.UNIVERSAL_DATE_TIME_FORMAT);
      break;
    /* TimeWithSecond 格式只有 hh:mm:ss，無法用moment轉換，僅需用text呈現即可
    case DataType.TimeWithSecond:
    result = moment(text).format(DATETIME.UNIVERSAL_TIME_WITH_SECOND_FORMAT);
    break;
    */
    case DataType.TimeWithOutSecond:
      result = moment(text).format(DATETIME.UNIVERSAL_TIME_FORMAT);
      break;
    case DataType.Number:
      result = numberFormatter(text);
      break;
    case DataType.NumberWithMantissa2:
      result = numberFormatter(text, 2);
      break;
    case DataType.NumberWithMantissa4:
      result = numberFormatter(text, 4);
      break;
    case DataType.NumberWithMantissa2AndPercentageSign:
      result = numberFormatter(text, 2);
      if (text !== '') result += '%';
      break;
    case DataType.NumberWithMantissa2AndPreserveZero:
      result = numberFormatter(text, 2, false);
      break;
    case DataType.NumberWithMantissa2AndPreserveZeroAndPercentageSign:
      result = numberFormatter(text, 2, false);
      if (text !== '') result += '%';
      break;
    case DataType.NumberWithMantissa4AndPreserveZero:
      result = numberFormatter(text, 4, false);
      break;
  }

  if (text === '' || text === null || text === undefined || text === DATA.NULL) result = DATA.NULL;
  return result;
}

export function dataFormatterWithoutNumber(text, dataType) {
  //if (text === '' || text === null || text === DATA.NULL) return DATA.NULL;

  let result: string = text;
  switch (dataType) {
    case DataType.Boolean:
      result = text ? '是' : '否';
      return result;
      break;
    case DataType.Date:
      result = moment(text).format(DATETIME.UNIVERSAL_DATE_FORMAT);
      break;
  }

  if (text === '' || text === null || text === undefined || text === DATA.NULL) result = DATA.NULL;
  return result;
}

export function stringIsNullOrEmpty(v) {
  return v === null || v === '' || v === DATA.NULL;
}
