import { Box, Button, createStyles, Dialog, Grid } from '@material-ui/core';
import DATA from 'constant/data';
import Immutable, { Map } from 'immutable';
import React, { Component } from 'react';
import palette from 'style/palette';
import withStyles, { WithStyles } from '@material-ui/styles/withStyles';
import DataType from 'constant/datatype';
import { dataFormatter, stringIsNullOrEmpty } from 'lib/string';
import { fetchGetPdf } from 'lib/url';

export interface BondBasicInfoCell {
  title: string;
  key: string;
  xs?: 6 | 12;
  sm?: 2 | 4;
  dataType?: DataType;
}

const defaultCells: BondBasicInfoCell[] = [
  { title: '商品名稱', key: 'bondName', xs: 12, sm: 4 },
  { title: '發行人', key: 'issuer', xs: 12, sm: 4 },
  { title: 'ISIN', key: 'isin', xs: 12, sm: 4 },

  { title: '幣別', key: 'currency' },
  { title: '產業', key: 'industry' },
  { title: '清償順位', key: 'paymentRank' },
  //{ title: '信評(穆迪/標普/惠譽)', key: 'rating' },
  { title: '信評(彭博)', key: 'ratingBBG' },
  { title: '最小交易單位', key: 'minPiece', dataType: DataType.Number },
  { title: '最小增額單位', key: 'minIncrement', dataType: DataType.Number },

  { title: '票息類型', key: 'couponTypeDesc' },
  { title: '票面利率', key: 'coupon' },
  { title: '配息頻率', key: 'couponFrequencyDesc' },
  { title: '付息月份', key: 'interestMonths' },
  { title: '下一付息日', key: 'nextCouponDate', dataType: DataType.Date },
  { title: '計息基準', key: 'dayCountDesc' },

  { title: '到期日', key: 'maturityDate', dataType: DataType.Date },
  { title: '距到期年', key: 'durationYears' },
  { title: '到期收益率(YTM)', key: 'ytm', dataType: DataType.NumberWithMantissa2 },
  { title: '是否為投資等級債券', key: 'investmentGrade', dataType: DataType.Boolean },
  { title: '是否僅限專業投資人申購', key: 'pi', dataType: DataType.Boolean },
  { title: '產品說明書', key: 'attachmentFileName' },

  { title: '下一買回日', key: 'nextCallDate', dataType: DataType.Date },
  { title: '距買回年', key: 'durationCallYears' },
  { title: '買回收益率(YTC)', key: 'buyYTC', dataType: DataType.NumberWithMantissa2 },
  { title: '發行人是否可提前買回', key: 'callable', dataType: DataType.Boolean },
  { title: '備註', key: 'remark' },
];

const styles = createStyles({
  content: {
    fontSize: 18,
    color: '#000',
    lineHeight: 1,
    fontWeight: 'bold',
  },
  title: {
    marginBottom: 6,
    fontSize: 13,
    color: '#666',
    lineHeight: 1,
  },
  wrapper: {
    backgroundColor: '#F5F5F5',
    padding: '11px 14px',
    borderRadius: 3,
    height: '100%',
  },
  link: {
    color: palette.link,
    textDecoration: 'none',
    wordBreak: 'break-all',
  },
  dialog: {
    textAlign: 'center',
  },
});

interface BondBasicInfoProps extends WithStyles<typeof styles> {
  data?: { result: {}[] };
  onInitComplete?: (bondName: string) => void;
  cells?: BondBasicInfoCell[];
  attachmentPath?: string;
}
interface States {
  open: boolean;
}

class BondBasicInfo extends Component<BondBasicInfoProps, States> {
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  constructor(props: BondBasicInfoProps) {
    super(props);
    this.state = {
      open: false,
    };
  }

  public componentDidMount(): void {
    if (this.props.onInitComplete) {
      let bondName = (this.props.data) ? this.props.data['bondName'] : '';
      this.props.onInitComplete(bondName);
    }
  }

  private handleAttachmentClick(url: string, options?: {
    param?: string | object | undefined;
  }) {
    fetchGetPdf(url, options).catch(e => {
      this.setState({ open: true });
    });
  }

  private renderAccachment(key: string, text: string, classlink: string, attachmentPath: string, data: any) {
    let aParam = {
      url: ``,
      name: '',
      aTag: false,
      param: {},
    };
    switch (key) {
      case 'attachmentFileNameTS':
        //中文產品說明書
        aParam.name = '點我下載';
        aParam.url = `${attachmentPath}/TS/${data.get('productCode')}`;
        aParam.aTag = true;
        break;
      case 'attachmentFileNameIIS':
        //中文投資人須知
        aParam.name = '點我下載';
        aParam.url = `${attachmentPath}/IIS/${data.get('productCode')}`;
        aParam.aTag = true;
        break;
      case 'attachmentProspectus'://債券公開說明書        
      case 'attachmentFinalTerms'://債券產品說明書
        aParam.name = '點我下載';
        if (text != DATA.NULL) {
          aParam.aTag = true;
          aParam.url = `${attachmentPath}`;
          aParam.param = { param: { manualName: text } }
        }
        break
      default:
        aParam.aTag = false;
        break;
    }

    return aParam.aTag ? (
      <a
        className={classlink}
        href="javascript:void(0)"
        onClick={() => {
          this.handleAttachmentClick(aParam.url, aParam.param);
          return false;
        }}
        download
      >
        {aParam.name}
      </a>
    ) : (
      text
    );
  }

  public render() {
    let { data, classes, cells = defaultCells, attachmentPath = '/maintain/attachment/prospectus' } = this.props;

    let d = Immutable.fromJS(data).getIn(['result', 0], Map<string, string | number>());
    if (d.size == 0) {
      d = Immutable.fromJS(data);
    }
    const fromBTS = sessionStorage.getItem('fromBTS') === 'true';
    attachmentPath = fromBTS == true ? '/bts' + attachmentPath : attachmentPath;
    return (
      <>
        <Grid container spacing={1}>
          {cells.map(({ title, key, xs = 6, sm = 2, dataType }) => {
            const text = dataFormatter(d.get(key, DATA.NULL), dataType);
            return (
              <Grid key={key} item xs={xs} sm={sm}>
                <div className={classes.wrapper}>
                  <div className={classes.title}>{title}</div>
                  <div className={classes.content}>
                    {this.renderAccachment(key, text, classes.link, attachmentPath, d)}
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
        <Dialog open={this.state.open}>
          <Box m={1} className={classes.dialog}>
            <Box m={1}>商品文件維護中</Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                this.setState({ open: false });
              }}
            >
              OK
            </Button>
          </Box>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(BondBasicInfo);
