import DATA from 'constant/data';
import numbro from 'numbro';

export const numberFormatter = (num, mantissa = 10, trimMantissa = true) => {
  if (num === '' || num === null || num === undefined || num === DATA.NULL) return DATA.NULL;

  return (typeof num === 'string' && num === '') || num === null
    ? ''
    : numbro(num).format({ mantissa, trimMantissa, thousandSeparated: true });
}


export const PercentagesFormat = (num, mantissa = 10) => {
  if (num === '' || num === null || num === undefined || num === DATA.NULL) return DATA.NULL;

  return (typeof num === 'string' && num === '') || num === null
    ? ''
    : numbro(num).format({ mantissa, output: 'percent' });
};

/**
 * @description 保留尾數 0 & . 避免 formatting 時被移除
 * @param rawText
 */
export function numberPreserveDotAndZeroOnLast(rawText: string) {
  const isLastCharIsZero = /^.+\.\d*0$/.test(rawText);
  const isLastCharIsDot = /\.$/.test(rawText);

  if (isLastCharIsDot) {
    return '.';
  } else if (isLastCharIsZero) {
    const suffixZero = rawText.match(/\.?0+$/);

    return suffixZero !== null ? suffixZero[0] : '';
  }

  return '';
}
